<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout justify-center wrap px-2 pb-4>
      <v-flex xs12 align-self-center>
        <v-card color="#F7F7F7" tile flat height="100%">
          <v-layout wrap justify-center pa-4 px-6>
            <v-flex xs12>
              <v-flex xs12 py-5 text-uppercase>
                <span style="font-family: poppinsbold; font-size: 20px"
                  >₹ Pricing Details </span
                ><br />
              </v-flex>
              <v-layout justify-center wrap>
                <v-flex text-left xs8>
                  <span class="contentsemi"
                    >Price ({{ qty }} Item<span v-if="qty > 1">s</span>)</span
                  >
                </v-flex>
                <v-flex text-end xs4>
                  <span class="contentsemi">₹ {{ newprice }}</span>
                </v-flex>
              </v-layout>
              <!-- <v-layout justify-center wrap>
                <v-flex text-left xs8>
                  <span class="contentsemi">Delivery Fee</span>
                </v-flex>
                <v-flex text-end xs4>
                  <span class="contentsemi"
                    >₹ {{ finalCalculation.deliveryCharge }}</span
                  >
                </v-flex>
              </v-layout> -->
              <v-layout justify-center wrap>
                <v-flex text-left xs8>
                  <span class="contentsemi">Payment Gateway Charge</span>
                </v-flex>
                <v-flex text-end xs4>
                  <span class="contentsemi"
                    >₹ {{ finalCalculation.paymentgatewayCharge }}</span
                  >
                </v-flex>
              </v-layout>
              <v-layout justify-center wrap>
                <!-- <v-flex text-left xs6>
                  <span class="contentsemi">Tax:</span>
                </v-flex> -->
                <!-- <v-flex text-end xs6>
                  <span class="contentsemi">₹ {{ tax }}</span>
                </v-flex> -->
              </v-layout>
              <v-layout justify-center wrap>
                <v-flex text-left xs8>
                  <span style="font-family: poppinssemibold; color: #000"
                    >Order Total</span
                  >
                </v-flex>
                <v-flex text-end xs4>
                  <span style="font-family: poppinssemibold; color: #000"
                    >₹ {{ finalCalculation.finalAmount }}</span
                  >
                </v-flex>
              </v-layout>
              <v-layout justify-center wrap pt-10>
                <v-flex xs12>
                  <v-layout justify-center wrap>
                    <v-flex xs11>
                      <span style="font-family: poppinsbold; color: #474747"
                        >Shipping Information</span
                      >
                    </v-flex>
                    <v-flex xs1 v-if="newAddresss.length > 0">
                      <v-icon size="15px" @click="editdialog = true"
                        >mdi-pencil-outline</v-icon
                      >&nbsp;&nbsp;&nbsp;

                      <v-dialog v-model="editingitem.delete" max-width="600px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-on="on" v-bind="attrs" size="15px"
                            >mdi-delete-outline</v-icon
                          >
                        </template>
                        <v-card>
                          <v-card-title
                            >Do you want to remove this delivery
                            address?</v-card-title
                          >
                          <v-card-actions class="align-end">
                            <v-spacer></v-spacer>
                            <v-btn
                              dark
                              color="#FF4444"
                              small
                              depressed
                              :ripple="false"
                              @click="editingitem.delete = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              dark
                              color="#53A874"
                              small
                              depressed
                              :ripple="false"
                              @click="removeAddress(editingitem._id)"
                              >OK</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <!-- <v-icon size="13px" @click="removeAddress(editingitem._id)"
                        >mdi-delete-outline</v-icon
                      > -->
                    </v-flex>
                  </v-layout>
                  <v-layout justify-center wrap v-if="newAddresss.length > 0">
                    <v-flex xs12 v-for="(item, i) in newAddresss" :key="i">
                      <v-layout wrap>
                        <v-flex xs2>
                          <v-radio-group
                            dense
                            v-model="radioGroup"
                            hide-details
                          >
                            <v-radio
                              :id="item._id"
                              :value="item._id"
                              @click="myedit(item)"
                              color="#48B179"
                            ></v-radio>
                          </v-radio-group>
                          <!-- <v-radio-group
                            dense
                            hide-details
                            mandatory
                            v-model="item.radios"
                            
                            @click="myedit(item)"
                          >
                            <v-radio :id="item._id" :value="item" color="#48B179"></v-radio>
                          </v-radio-group> -->
                          <!-- <v-checkbox
                            v-model="item.radios"
                            hide-details
                            :ripple="false"
                            :id="item._id"
                            :key="i"
                            color="#48B179"
                          ></v-checkbox> -->
                        </v-flex>
                        <v-flex xs8 pt-4>
                          <span class="shopping"
                            >{{ item.firstname }} {{ item.lastname }}</span
                          >
                        </v-flex>
                      </v-layout>
                      <v-layout justify-center wrap pb-2>
                        <v-flex xs8>
                          <span class="shopping">{{ item.streetAddress }}</span>
                        </v-flex>
                      </v-layout>
                      <v-layout justify-center wrap pb-2>
                        <v-flex xs8>
                          <span class="shopping">{{ item.phone }}</span>
                        </v-flex>
                      </v-layout>
                      <v-layout justify-center wrap>
                        <v-flex xs8>
                          <span class="shopping">{{ item.email }}</span>
                        </v-flex>
                      </v-layout>
                      <v-layout wrap justify-center pt-2>
                        <v-flex xs11 :key="i">
                          <v-divider></v-divider>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                  <!-- <v-layout wrap justify-center pt-2>
                    <v-flex xs11 :key="i">
                      <v-divider></v-divider>
                    </v-flex>
                  </v-layout> -->
                </v-flex>
              </v-layout>
              <v-dialog
                v-model="editdialog"
                max-width="500px"
                transition="dialog-transition"
                persistent
              >
                <v-card>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-layout wrap justify-center pa-4>
                      <v-flex xs12>
                        <v-layout wrap justify-center pb-4>
                          <v-flex xs12>
                            <span
                              style="
                                font-family: opensanssemibold;
                                font-size: 20px;
                                color: #000000;
                              "
                              >Edit Address</span
                            >
                          </v-flex>
                        </v-layout>
                        <v-layout wrap justify-start>
                          <v-flex xs12 sm12 px-2 text-left>
                            <span class="shopping">First Name</span>
                            <v-text-field
                              v-model="editingitem.firstname"
                              color="#005f32"
                              :rules="nameRules"
                              hide-selected
                              outlined
                              dense
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                        <v-layout wrap justify-start>
                          <v-flex xs12 sm12 px-2 text-left>
                            <span class="shopping">Last Name</span>
                            <v-text-field
                              v-model="editingitem.lastname"
                              :rules="nameRules"
                              color="#005f32"
                              hide-selected
                              outlined
                              dense
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                        <v-layout wrap justify-start>
                          <v-flex xs12 sm12 px-2 text-left>
                            <span class="shopping"> Email</span>
                            <v-text-field
                              v-model="editingitem.email"
                              :rules="emailRules"
                              color="#005f32"
                              hide-selected
                              outlined
                              dense
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                        <v-layout wrap justify-start>
                          <v-flex xs12 sm12 px-2 text-left>
                            <span class="shopping">Phone</span>
                            <v-text-field
                              v-model="editingitem.phone"
                              :rules="phoneRules"
                              color="#005f32"
                              hide-selected
                              outlined
                              dense
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                        <v-layout wrap justify-start>
                          <v-flex xs12 sm12 px-2 text-left>
                            <span class="shopping">Street Address</span>
                            <v-textarea
                              v-model="editingitem.streetAddress"
                              outlined
                              color="#005f32"
                            ></v-textarea>
                          </v-flex>
                        </v-layout>
                        <v-layout wrap justify-start>
                          <v-flex xs12 sm12 px-2 text-left>
                            <span class="shopping"> Pincode</span>
                            <v-text-field
                              v-model="pincodes"
                              type="number"
                              color="#005f32"
                              outlined
                              hide-details
                              dense
                            ></v-text-field>
                            <span style="color: red; font-size: 10px">{{
                              msg1
                            }}</span>
                            <!-- <v-text-field
                              v-model="pincode1"
                              type="number"
                              color="#005f32"
                              hide-selected
                              :rules="pincodeRules"
                              outlined
                              dense
                            ></v-text-field> -->
                          </v-flex>
                        </v-layout>
                        <v-layout wrap justify-start>
                          <v-flex xs12 sm12 px-2 text-left>
                            <span class="shopping"> City</span>
                            <v-text-field
                              v-model="editingitem.city"
                              color="#005f32"
                              hide-selected
                              outlined
                              dense
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                        <v-layout wrap justify-start>
                          <v-flex xs12 sm12 px-2 text-left>
                            <span class="shopping"> District</span>
                            <v-text-field
                              v-model="editingitem.district"
                              color="#005f32"
                              hide-selected
                              outlined
                              dense
                            ></v-text-field>
                          </v-flex>
                        </v-layout>
                        <v-layout wrap justify-start>
                          <v-flex xs12 sm12 px-2 text-left>
                            <span class="shopping"> State</span>
                            <v-select
                              v-model="editingitem.state"
                              :items="stateList"
                              item-text="name"
                              item-value="name"
                              :rules="stateRules"
                              outlined
                              dense
                            ></v-select>
                            <!-- <v-text-field
                              v-model="editingitem.state"
                              color="#005f32"
                              readonly
                              hide-selected
                              outlined
                              dense
                            ></v-text-field> -->
                          </v-flex>
                        </v-layout>
                        <!-- <v-layout wrap justify-start>
                        <v-flex xs12 sm12 px-2 text-left>
                          <span class="shopping"> Country</span>
                          <v-text-field
                            v-model="editingitem.country"
                            color="#005f32"
                            hide-selected
                            outlined
                            dense
                            
                          ></v-text-field>
                        </v-flex>
                      </v-layout> -->

                        <v-layout wrap justify-end py-4>
                          <v-flex xs6 sm4 px-2>
                            <v-btn
                              block
                              tile
                              color="#757575"
                              outlined
                              :ripple="false"
                              depressed
                              @click="editdialog = false"
                            >
                              <span
                                style="
                                  font-family: sofiaProSemiBold;
                                  font-size: 16px;
                                  text-transform: none;
                                  color: #000000;
                                "
                              >
                                Cancel
                              </span>
                            </v-btn>
                          </v-flex>
                          <v-flex xs6 sm4 px-2>
                            <v-btn
                              tile
                              block
                              :disabled="!valid || String(pincodes).length != 6"
                              dark
                              color="#53A874"
                              depressed
                              class="text-capitalize"
                              @click="validateInput(editingitem, 'edit')"
                              :ripple="false"
                            >
                              Apply
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-form>
                </v-card>
              </v-dialog>
              <v-flex xs12 px-4 py-5>
                <v-layout wrap justify-start>
                  <v-flex xs6>
                    <span
                      style="
                        color: #474747;
                        font-family: opensanssemibold;
                        font-size: 14px;
                      "
                      >Add new Address</span
                    >
                  </v-flex>
                  <v-flex xs6 text-right>
                    <v-dialog
                      v-model="dialog"
                      max-width="500px"
                      transition="dialog-transition"
                      persistent
                      :ripple="false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          class="tableValue"
                          style="font-size: 14px; color: #606060"
                        >
                          <v-icon :ripple="false" v-bind="attrs" v-on="on"
                            >mdi-plus</v-icon
                          >
                        </span>
                      </template>
                      <v-card>
                        <v-form ref="form" v-model="valid" lazy-validation>
                          <v-layout wrap justify-center pa-4>
                            <v-flex xs12>
                              <v-layout wrap justify-center pb-4>
                                <v-flex xs12>
                                  <span
                                    style="
                                      font-family: opensanssemibold;
                                      font-size: 20px;
                                      color: #000000;
                                    "
                                    >Add New Address</span
                                  >
                                </v-flex>
                              </v-layout>
                              <v-layout wrap justify-start>
                                <v-flex xs12 sm12 px-2 text-left>
                                  <span class="shopping">First Name</span>
                                  <v-text-field
                                    v-model="newAddress.firstname"
                                    color="#005f32"
                                    hide-selected
                                    :rules="nameRules"
                                    outlined
                                    dense
                                  ></v-text-field>
                                </v-flex>
                              </v-layout>
                              <v-layout wrap justify-start>
                                <v-flex xs12 sm12 px-2 text-left>
                                  <span class="shopping">Last Name</span>
                                  <v-text-field
                                    v-model="newAddress.lastname"
                                    color="#005f32"
                                    hide-selected
                                    :rules="nameRules"
                                    outlined
                                    dense
                                  ></v-text-field>
                                </v-flex>
                              </v-layout>
                              <v-layout wrap justify-start>
                                <v-flex xs12 sm12 px-2 text-left>
                                  <span class="shopping"> Email</span>
                                  <v-text-field
                                    v-model="newAddress.email"
                                    color="#005f32"
                                    hide-selected
                                    :rules="emailRules"
                                    outlined
                                    dense
                                  ></v-text-field>
                                </v-flex>
                              </v-layout>
                              <v-layout wrap justify-start>
                                <v-flex xs12 sm12 px-2 text-left>
                                  <span class="shopping">Phone</span>
                                  <v-text-field
                                    v-model="newAddress.phone"
                                    color="#005f32"
                                    type="number"
                                    hide-selected
                                    :rules="phoneRules"
                                    outlined
                                    dense
                              @wheel="$event.target.blur()"
                                  ></v-text-field>
                                </v-flex>
                              </v-layout>
                              <v-layout wrap justify-start>
                                <v-flex xs12 sm12 px-2 text-left>
                                  <span class="shopping">Street Address</span>
                                  <v-textarea
                                    v-model="newAddress.streetAddress"
                                    outlined
                                    color="#005f32"
                                  ></v-textarea>
                                </v-flex>
                              </v-layout>
                              <v-layout wrap justify-start>
                                <v-flex xs12 sm12 px-2 text-left>
                                  <span class="shopping"> Pincode</span>
                                  <v-text-field
                                    v-model="newAddress.pincode"
                                    type="number"
                                    color="#005f32"
                                    hide-selected
                                    :rules="pincodeRules"
                                    outlined
                              @wheel="$event.target.blur()" 
                                    dense
                                  ></v-text-field>
                                </v-flex>
                              </v-layout>
                              <v-layout wrap justify-start>
                                <v-flex xs12 sm12 px-2 text-left>
                                  <span class="shopping"> City</span>
                                  <v-text-field
                                    v-model="newAddress.city"
                                    color="#005f32"
                                    hide-selected
                                    outlined
                                    dense
                                  ></v-text-field>
                                </v-flex>
                              </v-layout>
                              <v-layout wrap justify-start>
                                <v-flex xs12 sm12 px-2 text-left>
                                  <span class="shopping"> District</span>
                                  <v-text-field
                                    v-model="newAddress.district"
                                    color="#005f32"
                                    hide-selected
                                    outlined
                                    dense
                                  ></v-text-field>
                                </v-flex>
                              </v-layout>
                              <v-layout wrap justify-start>
                                <v-flex xs12 sm12 px-2 text-left>
                                  <span class="shopping"> State</span>
                                  <v-select
                                    v-model="newAddress.state"
                                    :items="stateList"
                                    item-text="name"
                                    item-value="name"
                                    :rules="stateRules"
                                    outlined
                                    dense
                                  ></v-select>
                                  <!-- <v-text-field
                                    v-model="newAddress.state"
                                    color="#005f32"
                                    hide-selected
                                    readonly
                                    outlined
                                    dense
                                  ></v-text-field> -->
                                </v-flex>
                              </v-layout>
                              <!-- <v-layout wrap justify-start>
                                <v-flex xs12 sm12 px-2 text-left>
                                  <span class="shopping"> Country</span>
                                  <v-text-field
                                    v-model="newAddress.country"
                                    color="#005f32"
                                    hide-selected
                                    outlined
                                    dense
                                  ></v-text-field>
                                </v-flex>
                              </v-layout> -->

                              <v-layout wrap justify-end py-4>
                                <v-flex xs6 sm4 px-2>
                                  <v-btn
                                    block
                                    tile
                                    color="#757575"
                                    outlined
                                    :ripple="false"
                                    depressed
                                    @click="dialog = false"
                                  >
                                    <span
                                      style="
                                        font-family: poppinsregular;
                                        font-size: 14px;
                                        text-transform: none;
                                        color: #000000;
                                      "
                                    >
                                      Cancel
                                    </span>
                                  </v-btn>
                                </v-flex>
                                <v-flex xs6 sm4 px-2>
                                  <v-btn
                                    tile
                                    block
                                    :disabled="!valid || flag"
                                    dark
                                    color="#53A874"
                                    depressed
                                    class="text-capitalize"
                                    @click="validateInput(newAddress, 'add')"
                                    :ripple="false"
                                  >
                                    Apply
                                  </v-btn>
                                </v-flex>
                              </v-layout>
                            </v-flex>
                          </v-layout>
                        </v-form>
                      </v-card>
                    </v-dialog>
                  </v-flex>
                </v-layout>
              </v-flex>
              <!-- <v-flex xs12 py-5>
                <v-btn
                  dark
                  block
                  style="
                    background-color: #53a874;
                    font-family: poppinssemibold;
                  "
                  text
                  >Buy Now</v-btn
                >
              </v-flex> -->
              <v-layout wrap pb-8>
                <v-flex xs12>
                  <CheckOut
                    :productId="productId"
                    :quantity="qty"
                    :amount="finalCalculation.finalAmount"
                    :deliveryAddress="editingitem"
                    :unitId="unitId"
                    :sizeId="sizeId"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import CheckOut from "./checkOut";

export default {
  components: { CheckOut },
  props: [
    "qty",
    "newprice",
    "types",
    "productId",
    "unitId",
    "sizeId",
    "finalCalculation",
  ],

  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      newAddress: {
        firstname: null,
        lastname: null,
        streetAddress: null,
        city: null,
        district: null,
        state: null,
        // country: null,
        pincode: null,
        phone: null,
        email: null,
      },
      pincode: null,
      pincode1: null,
      msg: null,
      timeout: 5000,
      // radios: false,
      radios: "",
      dialog: false,
      editdialog: false,
      details: [],
      editingitem: [],
      deliveryfee: 0,
      // tax: 21.54,
      orderTotal: 0,
      radioGroup: null,
      newAddresss: [],
      valid: true,
      nameRules: [
        (v) => (v && v.length <= 15) || "Name must be less than 15 characters",
      ],
      phoneRules: [(v) => (v && v.length == 10) || "Invalid Phone number"],
      emailRules: [(v) => /.+@.+\..+/.test(v) || "E-mail must be valid"],
      pincodeRules: [(v) => (v && v.length == 6) || "Invalid Pincode"],
      pincodeRules1: [(v) => (v && v.length == 6) || "Invalid pincode"],
      stateRules: [(v) => v.length > 0 || "State is required"],
      stateList: [],
      pincodes: null,
      flag: false,
      msg1: "",
      appAddrs:null
    };
  },
  watch: {
    newprice() {
      // console.log(this.qty + 'child' + this.newprice)
      this.orderTotal = this.newprice + this.deliveryfee;
    },
    // pincode() {
    //   if (String(this.pincode).length == 6) {
    //     this.getState(this.newAddress, this.pincode);
    //   }

    //   //  console.log(this.getKeyByValue(val,"pincode"),"jii");
    // },
    // pincode1() {
    //   if (String(this.pincode1).length == 6) {
    //     this.getState(this.editingitem, this.pincode1);
    //   }
    // },
    pincodes() {
      if (String(this.pincodes).length != 6) {
        this.msg1 = "Invalid Pincode";
        // this.showSnackBar = true;
        // console.log(this.pincodes)
        this.flag = true;
      } else {
        this.msg1 = "";
        this.flag = false;
      }
    },
  },
  mounted() {
    this.getData();
    this.getState();
  },
  methods: {
    getState() {
      axios({
        method: "GET",
        url: "/state/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          // this.appLoading = false;
          if (response.data.status) {
            this.stateList = response.data.data;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          // this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    // getState(item, pincode) {
    //   this.appLoading = true;
    //   axios({
    //     method: "GET",
    //     url: "/purchase/getaddress",
    //     headers: {
    //       token: localStorage.getItem("token"),
    //     },
    //     params: { pincode: pincode },
    //   })
    //     .then((response) => {
    //       this.appLoading = false;
    //       if (response.data.status) {
    //         var newAddress;
    //         newAddress = response.data.data;
    //         if (newAddress) {
    //           // if (newAddress.City) item.city = newAddress.City;
    //           // else item.city=null
    //           // if (newAddress.DistrictsName)
    //           //   item.district = newAddress.DistrictsName;
    //           // else item.district=null

    //           if (newAddress.State) item.state = newAddress.State;
    //           else item.state = null;
    //         }
    //       } else {
    //         this.msg = response.data.msg;
    //         this.showSnackBar = true;
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
    getData() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/me/product",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.details = response.data.data.deliveryaddress;
          this.appAddrs = response.data.data;
          // for (let i = 0; i < this.details.deliveryaddress.length; i++) {
          //   this.newAddresss[i]=(this.details.deliveryaddress[i])
          // }
          // this.newAddress = this.details;
          this.newAddresss = this.details;
          if (this.newAddresss.length > 0) {
            this.radioGroup = this.newAddresss[0]._id;
            this.editingitem = this.newAddresss[0];
            this.pincodes = this.newAddresss[0].pincode;
            this.$emit("stepper", {
              deliveryAddressId: this.newAddresss[0]._id,
            });
          }
          // if(!this.newAddresss.length>0) {
            this.newAddress.firstname = this.appAddrs.firstname
            this.newAddress.lastname = this.appAddrs.lastname
            this.newAddress.email = this.appAddrs.email
            this.newAddress.phone = this.appAddrs.phone
            this.newAddress.pincode = this.appAddrs.pincode
            this.newAddress.streetAddress = this.appAddrs.streetAddress
            this.newAddress.state = this.appAddrs.state
            this.newAddress.district = this.appAddrs.district
            this.newAddress.city = this.appAddrs.city
            this.newAddress.country = this.appAddrs.country
          // }
          // console.log(this.newAddresss);
          // this.removeAddress("610396f2fe36b01444c539c6");
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    validateInput(item, type) {
      if (!item.firstname) {
        this.msg = "Please Provide First Name";
        this.showSnackBar = true;
        return;
      } else if (!item.lastname) {
        this.msg = "Please Provide Last Name";
        this.showSnackBar = true;
        return;
      } else if (!item.streetAddress) {
        this.msg = "Please Provide Address";
        this.showSnackBar = true;
        return;
      } else if (!item.phone) {
        this.msg = "Please Provide Phone";
        this.showSnackBar = true;
        return;
      } else if (!item.email) {
        this.msg = "Please Provide Email";
        this.showSnackBar = true;
        return;
      } else if (!item.city) {
        this.msg = "Please Provide City";
        this.showSnackBar = true;
        return;
      } else if (!item.district) {
        this.msg = "Please Provide District";
        this.showSnackBar = true;
        return;
      } else if (!item.state) {
        this.msg = "Please Provide State";
        this.showSnackBar = true;
        return;
      }
      //  else if (!item.country) {
      //   this.msg = "Please Provide Country";
      //   this.showSnackBar = true;
      //   return;
      // }
      // else if (!item.pincode) {
      //   this.msg = "Please Provide Pincode";
      //   this.showSnackBar = true;
      //   return;
      // }
      else if (this.$refs.form.validate()) {
        if (type == "add") this.add();
        else if (type == "edit") this.edit();
      }
    },
    add() {
      var adduser = {};
      // this.newAddress.pincode = this.pincode;
      adduser["firstname"] = this.newAddress.firstname;
      adduser["lastname"] = this.newAddress.lastname;
      adduser["streetAddress"] = this.newAddress.streetAddress;
      adduser["city"] = this.newAddress.city;
      adduser["district"] = this.newAddress.district;
      adduser["state"] = this.newAddress.state;
      // adduser["country"] = this.newAddress.country;
      adduser["pincode"] = this.newAddress.pincode;
      adduser["phone"] = this.newAddress.phone;
      adduser["email"] = this.newAddress.email;
      // adduser["id"] = this.newAddress._id;
      axios({
        method: "POST",
        url: "/deliveryaddress/add",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: adduser,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Address Added Successully";
            this.dialog = false;
            // Object.keys(this.product).forEach(
            //   (key) => (this.product[key] = null)
            // );
            this.formReset();
            this.getData();
            this.showSnackBar = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    myedit(item) {
      this.editingitem = item;
      this.pincodes = item.pincode;

      // this.editAddress(this.editingitem);
      this.$emit("stepper", {
        deliveryAddressId: item._id,
      });
      // this.radioGroup=null
    },

    edit() {
      this.appLoading = true;
      var user = {};
      // this.editingitem.pincode = this.pincode1;
      user["firstname"] = this.editingitem.firstname;
      user["lastname"] = this.editingitem.lastname;
      user["streetAddress"] = this.editingitem.streetAddress;
      user["city"] = this.editingitem.city;
      user["district"] = this.editingitem.district;
      user["state"] = this.editingitem.state;
      // user["country"] = this.editingitem.country;
      user["pincode"] = this.pincodes;
      user["phone"] = this.editingitem.phone;
      user["email"] = this.editingitem.email;
      user["id"] = this.radioGroup;
      axios({
        method: "POST",
        url: "/deliveryaddress/edit",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: user,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.editdialog = false;
            this.msg = response.data.msg;
            this.showSnackBar = true;
            // this.getData();
            this.$emit("stepper", {
              getPrice: true,
            });
          } else {
            // this.msg1 = "";
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formReset() {
      this.newAddress.firstname = "";
      this.newAddress.lastname = "";
      this.newAddress.streetAddress = "";
      this.newAddress.city = "";
      this.newAddress.district = "";
      this.newAddress.state = "";
      // this.newAddress.country = "";
      this.newAddress.pincode = "";
      this.newAddress.phone = "";
      this.newAddress.email = "";
      // this.newAddress._id = '';
      this.resetValidation();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    removeAddress(ids) {
      axios({
        method: "POST",
        url: "/deliveryaddress/remove",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: { id: ids },
      }).then((response) => {
        this.msg = response.data.msg;

        this.showSnackBar = true;
        this.getData();
      });
    },
  },
};
</script>
