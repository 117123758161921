<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-layout wrap>
      <v-flex xs12>
        <v-btn
          @click="validateInput"
          depressed
          small
          dark
          block
          :ripple="false"
          color="#53A874"
        >
          <span class="poppinsbold"> CHECKOUT</span>
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: [
    "productId",
    "deliveryAddress",
    "quantity",
    "amount",
    "unitId",
    "sizeId",
  ],

  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      payId: "",
      packageConfirmationDialog: false,
      vehicles: [],
    };
  },
  computed: {
    userData() {
      return this.$store.state.userData.guest;
    },
  },
  mounted() {},
  methods: {
    validateInput() {
      if (this.deliveryAddress._id) this.checkOut();
      else 
      {
        this.showSnackBar = true;
      this.msg = "Please select an Address";
      }
    },

    checkOut() {
      this.appLoading = true;
      // var prodd=this.productId
      axios({
        method: "POST",
        url: "/purchase/checkout",
        data: {
          amount: this.amount,
          product: this.productId,
          deliveraddressid: this.deliveryAddress._id,
          quantity: this.quantity,
          sizeid: this.sizeId,
          unitid: this.unitId,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            window.id = response.data.paymentdata._id;
            window.orderid = response.data.data.order_id;
            setTimeout(function () {}, 10000);
            window.location.href=response.data.redirecturl
            // var options = {
            //   // key: "rzp_test_hOMmQHmHSDvzgV",
            //   key: "rzp_live_6tBA1QlJeEv8qF",
            //   amount: Number(this.amount * 100),
            //   name: this.userData.name,
            //   currency: "INR",
            //   description: "PARAMBIKULAM",
            //   image: "https://jolly-volhard-bc2f0b.netlify.com/favicon.ico",
            //   order_id: orderid,

            //   handler: function (response) {
            //     location.href =
            //       "https://www.parambikulam.org/Shopping/PaywaitScreen/" +
            //       id +
            //       "/" +
            //       orderid +"/"+prodd

            //       console.log(this.productId,"hkjh")
                  
            //   },

            //   prefill: {
            //     name: this.userData.name,
            //     email: this.userData.email,
            //     contact: this.userData.phoneno,
            //   },

            //   theme: {
            //     color: "#53a874",
            //   },
            
            // };
            // var rzp1 = new Razorpay(options);
            // rzp1.open();
            // this.payid = response.data.donationdata._id;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
  },
};
</script>
